import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Container = makeShortcode("Container");
const Grid = makeShortcode("Grid");
const DoiLogoImg = makeShortcode("DoiLogoImg");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Box component='footer' display="flex" alignItems="center" bgcolor="info.dark" pt={4} pb={4} style={{
      position: 'relative',
      zIndex: 275,
      top: 6
    }} displayPrint="none" mdxType="Box">
  <Container maxWidth="lg" mdxType="Container">
    <Grid container mdxType="Grid">
      <Grid item xs={12} sm={2} mdxType="Grid">
        <Box p={4} display={{
              xs: 'none',
              sm: 'block'
            }} mdxType="Box">
          <DoiLogoImg mdxType="DoiLogoImg"></DoiLogoImg>
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} mdxType="Grid">
        <Box color="white" mdxType="Box">
          <h2>Built in the open</h2>
          <Box mr={4} mdxType="Box">
This site <Link href="https://github.com/DOI-ONRR/nrrd/releases" style={{
                  color: 'white'
                }} mdxType="Link">{props.data.site.siteMetadata.version} <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link> is powered by <Link href="/downloads" style={{
                  color: 'white'
                }} mdxType="Link">open data</Link> and <Link href='https://github.com/DOI-ONRR/nrrd' style={{
                  color: 'white'
                }} mdxType="Link">source code <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>. We welcome contributions
and comments on <Link href='https://github.com/DOI-ONRR/nrrd/issues' style={{
                  color: 'white'
                }} mdxType="Link">GitHub <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>. We write about how we work on this
site on <Link href='https://blog-nrrd.doi.gov' style={{
                  color: 'white'
                }} mdxType="Link">our team's blog <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>.
          </Box>
          <Box color='white' fontSize={'caption.fontSize'} mt={4} mdxType="Box">
            <Link href='/' style={{
                  color: 'white'
                }} mdxType="Link">Home</Link>&nbsp;|&nbsp;
            <Link href='/about' style={{
                  color: 'white'
                }} mdxType="Link">About</Link>&nbsp;|&nbsp;
            <Link href='https://www.doi.gov/' style={{
                  color: 'white'
                }} mdxType="Link">Department of the Interior <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>&nbsp;|&nbsp;
            <Link href='https://www.doi.gov/privacy' style={{
                  color: 'white'
                }} mdxType="Link">Privacy Policy <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>&nbsp;|&nbsp;
            <Link href='https://www.doi.gov/foia' style={{
                  color: 'white'
                }} mdxType="Link">FOIA <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>&nbsp;|&nbsp;
            <Link href='https://www.usa.gov/' style={{
                  color: 'white'
                }} mdxType="Link">USA.gov <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>&nbsp;|&nbsp;
            <Link href='https://www.doioig.gov/' style={{
                  color: 'white'
                }} mdxType="Link">Inspector General <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>&nbsp;|&nbsp;
            <Link href='https://www.doi.gov/pmb/eeo/reports-repository' style={{
                  color: 'white'
                }} mdxType="Link">No Fear Act <OpenInNewIcon style={{
                    fontSize: 12,
                    fill: 'white'
                  }} mdxType="OpenInNewIcon" /></Link>&nbsp;|&nbsp;
            <Link href='/vdp' style={{
                  color: 'white'
                }} mdxType="Link">Vulnerability Disclosure Policy</Link>&nbsp;|&nbsp;
            <Link href='/information-quality' style={{
                  color: 'white'
                }} mdxType="Link">Information Quality</Link>&nbsp;|&nbsp;
            <Link href='/accessibility' style={{
                  color: 'white'
                }} mdxType="Link">Accessibility</Link>&nbsp;|&nbsp;
            <Link href='/plain-language' style={{
                  color: 'white'
                }} mdxType="Link">Plain Language</Link>
            
            
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} mdxType="Grid">
        <Box pt={4} color='white' fontSize={'caption.fontSize'} mdxType="Box">
          {`${props.data.site.siteMetadata.officeName},`}<br />
          {`${props.data.site.siteMetadata.informationDataManagement.name}`}<br />
          {`${props.data.site.siteMetadata.informationDataManagement.street}`}<br />
          {`${props.data.site.siteMetadata.informationDataManagement.city} ${props.data.site.siteMetadata.informationDataManagement.zip}`}<br />
          <Link href={`mailto:${props.data.site.siteMetadata.informationDataManagement.email}`} style={{
                color: 'white'
              }} mdxType="Link">
            {props.data.site.siteMetadata.informationDataManagement.email}
          </Link><br />
          <Link href={`mailto:${props.data.site.siteMetadata.informationDataManagement.contact}`} style={{
                color: 'white'
              }} mdxType="Link">
            {props.data.site.siteMetadata.informationDataManagement.contact}
          </Link>
        </Box>
      </Grid>
    </Grid>
  </Container>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from 'react'

const CompareContext = props => {
  return (
    <>
      {props.children}
    </>
  )
}

export default CompareContext

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "stateRedirects": ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "MD", "MA", "MI", "ME", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "al", "ak", "az", "ar", "ca", "co", "ct", "de", "fl", "ga", "hi", "id", "il", "in", "ia", "ks", "ky", "la", "md", "ma", "mi", "me", "mn", "ms", "mo", "mt", "ne", "nv", "nh", "nj", "nm", "ny", "nc", "nd", "oh", "ok", "or", "pa", "ri", "sc", "sd", "tn", "tx", "ut", "vt", "va", "wa", "wv", "wi", "wy"],
  "archiveRedirects": null,
  "redirects": [{
    "oldUrl": "/how-it-works/revenues/",
    "newUrl": "/how-revenue-works/revenues"
  }, {
    "oldUrl": "/how-it-works/ownership/",
    "newUrl": "/how-revenue-works/ownership"
  }, {
    "oldUrl": "/how-it-works/coal/",
    "newUrl": "/how-revenue-works/coal/"
  }, {
    "oldUrl": "/how-it-works/onshore-oil-gas/",
    "newUrl": "/how-revenue-works/onshore-oil-gas"
  }, {
    "oldUrl": "/how-it-works/onshore-renewables/",
    "newUrl": "/how-revenue-works/onshore-renewables"
  }, {
    "oldUrl": "/how-it-works/minerals/",
    "newUrl": "/how-revenue-works/minerals/"
  }, {
    "oldUrl": "/how-it-works/federal-revenue-by-company/",
    "newUrl": "/downloads/federal-revenue-by-company"
  }, {
    "oldUrl": "/how-it-works/land-and-water-conservation-fund/",
    "newUrl": "/how-revenue-works/lwcf"
  }, {
    "oldUrl": "/how-it-works/historic-preservation-fund/",
    "newUrl": "/how-revenue-works/hpf"
  }, {
    "oldUrl": "/how-it-works/",
    "newUrl": "/how-revenue-works"
  }, {
    "oldUrl": "/how-it-works/audits-and-assurances/",
    "newUrl": "/how-revenue-works/audits-and-assurances"
  }, {
    "oldUrl": "/how-it-works/aml-reclamation-program/",
    "newUrl": "/how-revenue-works/aml-reclamation-program"
  }, {
    "oldUrl": "/how-it-works/coal-excise-tax/",
    "newUrl": "/how-revenue-works/coal-excise-tax"
  }, {
    "oldUrl": "/how-it-works/offshore-oil-gas/",
    "newUrl": "/how-revenue-works/offshore-oil-gas"
  }, {
    "oldUrl": "/how-it-works/offshore-renewables/",
    "newUrl": "/how-revenue-works/offshore-renewables"
  }, {
    "oldUrl": "/how-it-works/gomesa/",
    "newUrl": "/how-revenue-works/gomesa"
  }, {
    "oldUrl": "/how-it-works/federal-laws/",
    "newUrl": "/how-revenue-works/federal-laws"
  }, {
    "oldUrl": "/how-it-works/federal-reforms/",
    "newUrl": "/how-revenue-works/federal-reforms"
  }, {
    "oldUrl": "/how-it-works/native-american-ownership-governance/",
    "newUrl": "/how-revenue-works/native-american-ownership-governance"
  }, {
    "oldUrl": "/how-it-works/native-american-production/",
    "newUrl": "/how-revenue-works/native-american-production"
  }, {
    "oldUrl": "/how-it-works/native-american-revenue/",
    "newUrl": "/how-revenue-works/native-american-revenue"
  }, {
    "oldUrl": "/how-it-works/native-american-economic-impact/",
    "newUrl": "/how-revenue-works/native-american-economic-impact"
  }, {
    "oldUrl": "/how-it-works/disbursements/",
    "newUrl": "/how-revenue-works/disbursements"
  }, {
    "oldUrl": "/how-it-works/reclamation-fund/",
    "newUrl": "/how-revenue-works/reclamation"
  }, {
    "oldUrl": "/how-it-works/federal-revenue-by-company/2018/",
    "newUrl": "/how-revenue-works"
  }, {
    "oldUrl": "/how-it-works/federal-revenue-by-company/2017/",
    "newUrl": "/how-revenue-works"
  }, {
    "oldUrl": "/how-it-works/federal-revenue-by-company/2016/",
    "newUrl": "/how-revenue-works"
  }, {
    "oldUrl": "/how-it-works/federal-revenue-by-company/2015/",
    "newUrl": "/how-revenue-works"
  }, {
    "oldUrl": "/how-it-works/federal-revenue-by-company/2014/",
    "newUrl": "/how-revenue-works"
  }, {
    "oldUrl": "/how-it-works/federal-revenue-by-company/2013/",
    "newUrl": "/how-revenue-works"
  }, {
    "oldUrl": "/how-revenue-works/land-and-water-conservation-fund/",
    "newUrl": "/how-revenue-works/lwcf"
  }, {
    "oldUrl": "/how-revenue-works/historic-preservation-fund/",
    "newUrl": "/how-revenue-works/hpf"
  }, {
    "oldUrl": "/how-revenue-works/federal-revenue-by-company/",
    "newUrl": "/query-data/?dataType=Federal%20revenue%20by%20company"
  }, {
    "oldUrl": "/downloads/revenue-by-month/",
    "newUrl": "/downloads/revenue"
  }, {
    "oldUrl": "/downloads/federal-revenue-by-location/",
    "newUrl": "/downloads/revenue/"
  }, {
    "oldUrl": "/downloads/federal-production/",
    "newUrl": "/downloads/production/"
  }, {
    "oldUrl": "/archive/",
    "newUrl": "https://archive.revenuedata.doi.gov/"
  }, {
    "oldUrl": "/archive/case-studies/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/"
  }, {
    "oldUrl": "/archive/case-studies/boone-logan-and-mingo/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/boone-logan-and-mingo/"
  }, {
    "oldUrl": "/archive/case-studies/campbell/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/campbell/"
  }, {
    "oldUrl": "/archive/case-studies/greenlee/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/greenlee/"
  }, {
    "oldUrl": "/archive/case-studies/pima/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/pima/"
  }, {
    "oldUrl": "/archive/case-studies/elko-and-eureka/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/elko-and-eureka/"
  }, {
    "oldUrl": "/archive/case-studies/humboldt-and-lander/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/humboldt-and-lander/"
  }, {
    "oldUrl": "/archive/case-studies/marquette/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/marquette/"
  }, {
    "oldUrl": "/archive/case-studies/st-louis/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/st-louis/"
  }, {
    "oldUrl": "/archive/case-studies/desoto/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/desoto/"
  }, {
    "oldUrl": "/archive/case-studies/tarrant-and-johnson/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/tarrant-and-johnson/"
  }, {
    "oldUrl": "/archive/case-studies/kern/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/kern/"
  }, {
    "oldUrl": "/archive/case-studies/north-slope/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/case-studies/north-slope/"
  }, {
    "oldUrl": "/archive/fossil-fuels/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/fossil-fuels/"
  }, {
    "oldUrl": "/archive/nonenergy-minerals/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/nonenergy-minerals/"
  }, {
    "oldUrl": "/archive/renewables/",
    "newUrl": "https://archive.revenuedata.doi.gov/archive/renewables/"
  }, {
    "oldUrl": "/explore/revenue/",
    "newUrl": "/query-data"
  }, {
    "oldUrl": "/blog/",
    "newUrl": "https://blog-nrrd.doi.gov/"
  }, {
    "oldUrl": "/blog/card-sort/",
    "newUrl": "https://blog-nrrd.doi.gov/card-sort/"
  }, {
    "oldUrl": "/blog/intern-onrr-dot-gov/",
    "newUrl": "https://blog-nrrd.doi.gov/intern-onrr-dot-gov/"
  }, {
    "oldUrl": "/blog/shaping/",
    "newUrl": "https://blog-nrrd.doi.gov/shaping/"
  }, {
    "oldUrl": "/blog/intern-experience/",
    "newUrl": "https://blog-nrrd.doi.gov/"
  }, {
    "oldUrl": "/blog/adding-a-product/",
    "newUrl": "https://blog-nrrd.doi.gov/adding-a-product/"
  }, {
    "oldUrl": "/blog/training-update/",
    "newUrl": "https://blog-nrrd.doi.gov/training-update/"
  }, {
    "oldUrl": "/blog/remote-design-studio/",
    "newUrl": "https://blog-nrrd.doi.gov/remote-design-studio/"
  }, {
    "oldUrl": "/blog/tool-agnostic/",
    "newUrl": "https://blog-nrrd.doi.gov/tool-agnostic/"
  }, {
    "oldUrl": "/blog/moving-to-database/",
    "newUrl": "https://blog-nrrd.doi.gov/moving-to-database/"
  }, {
    "oldUrl": "/blog/product-champion/",
    "newUrl": "https://blog-nrrd.doi.gov/product-champion/"
  }, {
    "oldUrl": "/blog/our-duty-design-for-accessibility/",
    "newUrl": "https://blog-nrrd.doi.gov/our-duty-design-for-accessibility/"
  }, {
    "oldUrl": "/blog/building-technical-with-team-members/",
    "newUrl": "https://blog-nrrd.doi.gov/building-technical-with-team-members/"
  }, {
    "oldUrl": "/blog/beyond-open-data/",
    "newUrl": "https://blog-nrrd.doi.gov/beyond-open-data/"
  }, {
    "oldUrl": "/blog/intern-blog-post/",
    "newUrl": "https://blog-nrrd.doi.gov/intern-blog-post/"
  }, {
    "oldUrl": "/blog/open-data-useful/",
    "newUrl": "https://blog-nrrd.doi.gov/open-data-useful/"
  }, {
    "oldUrl": "/blog/making-tough-content-choices/",
    "newUrl": "https://blog-nrrd.doi.gov/making-tough-content-choices/"
  }, {
    "oldUrl": "/blog/journey-mapping/",
    "newUrl": "https://blog-nrrd.doi.gov/journey-mapping/"
  }, {
    "oldUrl": "/blog/420cb6a425f38ab28ef4734acea8f1b2/IDM_Journey.pdf",
    "newUrl": "https://blog-nrrd.doi.gov/420cb6a425f38ab28ef4734acea8f1b2/IDM_Journey.pdf"
  }, {
    "oldUrl": "/blog/82fd9ae2a90fe9d067be6d5cb232a8df/LeaseLevelJourneyMap_040519.pdf",
    "newUrl": "https://blog-nrrd.doi.gov/82fd9ae2a90fe9d067be6d5cb232a8df/LeaseLevelJourneyMap_040519.pdf"
  }, {
    "oldUrl": "/blog/county-choropleth-prototype/",
    "newUrl": "https://blog-nrrd.doi.gov/county-choropleth-prototype/"
  }, {
    "oldUrl": "/blog/content-design-blog-authors/",
    "newUrl": "https://blog-nrrd.doi.gov/content-design-blog-authors/"
  }, {
    "oldUrl": "/blog/becoming-a-product-manager/",
    "newUrl": "https://blog-nrrd.doi.gov/becoming-a-product-manager/"
  }, {
    "oldUrl": "/blog/usability-testing-training/",
    "newUrl": "https://blog-nrrd.doi.gov/usability-testing-training/"
  }, {
    "oldUrl": "/blog/homepage-revamp-part-two/",
    "newUrl": "https://blog-nrrd.doi.gov/homepage-revamp-part-two/"
  }, {
    "oldUrl": "/blog/homepage-revamp/",
    "newUrl": "https://blog-nrrd.doi.gov/homepage-revamp/"
  }]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "Contact-us",
      "style": {
        "position": "relative"
      }
    }}>{`Contact us`}</h2>
    <p>{`Do you have questions about the data or need data that isn't here? Contact our data specialists at `}<a parentName="p" {...{
        "href": "mailto:onrrdatarequests@onrr.gov"
      }}>{`onrrdatarequests@onrr.gov`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
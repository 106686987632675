import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const UsFlagImg = makeShortcode("UsFlagImg");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Box displayPrint="none" display="flex" justifyContent="center" alignItems="center" bgcolor="#062135" height="30px" style={{
      transition: 'height .1s ease',
      position: 'relative',
      zIndex: 250,
      color: 'white'
    }} mdxType="Box">
  <UsFlagImg alt={'U.S. flag signifying that this is a United States Federal Government website'} mdxType="UsFlagImg" />
  <Box fontSize="caption.fontSize" m={{
        sm: 0.5,
        md: 1
      }} mdxType="Box">An official website of the U.S. government</Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
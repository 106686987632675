import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const WarningIconImg = makeShortcode("WarningIconImg");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#fef1d2" height="40px" mt={1} mb={1} mdxType="Box">
  <WarningIconImg mdxType="WarningIconImg" />
  <Box fontSize="caption.fontSize" m={{
        sm: 0.5,
        md: 1
      }} mdxType="Box">Due to the government shutdown, we are not able to update this site. We will resume work on the site when the shutdown ends.</Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { DATA_FILTER_CONSTANTS as DFC } from '../../constants';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Container = makeShortcode("Container");
const Typography = makeShortcode("Typography");
const Box = makeShortcode("Box");
const FederalLandPercentage = makeShortcode("FederalLandPercentage");
const Grid = makeShortcode("Grid");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Container maxWidth="lg" mdxType="Container">
  <Typography variant="h2" component="h1" mdxType="Typography">
    {props.pageContext.frontmatter.title}
  </Typography>
  <Box mdxType="Box">
    This page provides information about energy and minerals produced on federal land in {props.pageContext.frontmatter.title}. Federal land represents <FederalLandPercentage stateOrArea={props.pageContext.frontmatter.unique_id} mdxType="FederalLandPercentage" /> of all land in {props.pageContext.frontmatter.title}.
  </Box>
  <Box component="p" mdxType="Box">
    The Office of Natural Resources Revenue (ONRR) collects rent, royalty, and bonus payments from companies that lease federal land. Some of the money collected is then disbursed to the state. States then distribute the funds as part of their budgets, but we don't have data about how the states spend the funds.
  </Box>
  <Box mt={2} mdxType="Box">
    <Grid container spacing={1} mdxType="Grid">
      <Grid item xs={6} mdxType="Grid">
        <Link href={`/explore?dataType=${DFC.REVENUE}&location=NF,${props.pageContext.frontmatter.unique_id}/`} linkType="ExploreData" mdxType="Link">
            Explore revenue data in {props.pageContext.frontmatter.title} 
        </Link>
        <Link href={`/explore?dataType=${DFC.DISBURSEMENT}&location=NF,${props.pageContext.frontmatter.unique_id}/`} linkType="ExploreData" mdxType="Link">
          Explore disbursements data in {props.pageContext.frontmatter.title}
        </Link>
        <Link href={`/explore?dataType=${DFC.PRODUCTION}&location=NF,${props.pageContext.frontmatter.unique_id}/`} linkType="ExploreData" mdxType="Link">
          Explore production data in {props.pageContext.frontmatter.title}
        </Link>
      </Grid>
      <Grid item xs={6} mdxType="Grid">
        <Link href={`/query-data?dataType=${DFC.REVENUE}&stateOffshoreName=${props.pageContext.frontmatter.title}`} linkType="FilterTable" mdxType="Link">
            Query revenue data in {props.pageContext.frontmatter.title}
        </Link>
        <Link href={`/query-data?dataType=${DFC.DISBURSEMENT}&stateOffshoreName=${props.pageContext.frontmatter.title}&usStateName=${props.pageContext.frontmatter.title}`} linkType="FilterTable" mdxType="Link">
          Query disbursements data in {props.pageContext.frontmatter.title}
        </Link>
        <Link href={`/query-data?dataType=${DFC.PRODUCTION}&stateOffshoreName=${props.pageContext.frontmatter.title}`} linkType="FilterTable" mdxType="Link">
          Query production data in {props.pageContext.frontmatter.title}
        </Link>
      </Grid>
    </Grid>
  </Box>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
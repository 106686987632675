import React from 'react'

const ProductionTrends = () => {
  return (
    <>
      <span>Production trends</span>
    </>
  )
}

export default ProductionTrends

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const IconArchiveImg = makeShortcode("IconArchiveImg");
const Box = makeShortcode("Box");
const GlossaryTerm = makeShortcode("GlossaryTerm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <IconArchiveImg mdxType="IconArchiveImg" />
    <Box fontSize={'caption.fontSize'} ml={1} display='inline-block' mdxType="Box">
      <p>{`This content was created as part of the `}<GlossaryTerm mdxType="GlossaryTerm">{`USEITI`}</GlossaryTerm>{`. We no longer update this resource.`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
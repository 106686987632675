import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "Content-Partials",
      "style": {
        "position": "relative"
      }
    }}>{`Content Partials`}</h1>
    <h2 {...{
      "id": "Why",
      "style": {
        "position": "relative"
      }
    }}>{`Why`}</h2>
    <p>{`This dierectory contains mdx files that can be included in other mdx files as well as react components. These partials are used to allow content managers to easily update content that is used in multiple places in one spot.`}</p>
    <h2 {...{
      "id": "How",
      "style": {
        "position": "relative"
      }
    }}>{`How`}</h2>
    <p>{`Just add a file with title case and a .mdx extension.`}</p>
    <h3 {...{
      "id": "How-to-inlude-in-react-components",
      "style": {
        "position": "relative"
      }
    }}>{`How to inlude in react components`}</h3>
    <p>{`To easily access the content partial in a react component, add the `}<inlineCode parentName="p">{`includeId`}</inlineCode>{` variable in the frontmatter. A graphql query can then be made to easily filter and fine the content partial to inlcude in your component.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Also as a good practice please add the graphql query for the content partial to the constants file so its easily accessed and the query doesnt have to be written in various files.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      